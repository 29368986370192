import React from 'react';
import PropTypes from 'prop-types';
import Picture, {
  propTypes as PicturePropTypes,
} from '../../../components/01_atoms/DefaultPicture';

import styles from './index.module.scss';

/**
 * TODO: Update the component if needed.
 */
const BBHeroImage = ({ image, className = '', title = '', description = '', label = '' }) => (
  <div className={`bb bb-hero-image ${styles['bb-hero-image']} ${className}`}>
    <div className={styles['bb-hero-image__image-container']}>
      <Picture {...image} />
      {label && <div className={styles['bb-hero-image__label']}>{label}</div>}
    </div>

    {(label || title || description) && (
      <div className={styles['bb-hero-image__content']}>
        <div className="container">
          {label && <div className={styles['bb-hero-image__label']}>{label}</div>}
          {title && <h1 className={styles['bb-hero-image__title']}>{title}</h1>}
          {description && <div className={styles['bb-hero-image__description']}>{description}</div>}
        </div>
      </div>
    )}
  </div>
);

BBHeroImage.propTypes = {
  image: PropTypes.shape(PicturePropTypes).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string,
};

export default BBHeroImage;
